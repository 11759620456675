import logo from './logo.png';
import ig from './1.png';
import wpp from './2.png';
import './App.css';

function App() {
  return (
    <div className="App">
        <img src={logo} className="App-logo" alt="logo" />
        
        <a href='https://www.instagram.com/harmonyhive_/'> 
          <button>
            <img src={ig} className="Icon" alt="IG Logo" />
            Instagram
          </button>
        </a>
        
        
        <a href='https://api.whatsapp.com/send/?phone=%2B556199468583&text&type=phone_number&app_absent=0'>
          <button>
            <img src={wpp} className="Icon" alt="Whatsapp Logo" />
            WhatsApp
          </button>
        </a>
        
        <footer>
           <p>© Harmony Hive Music Business</p>
        </footer>
    </div>
  );
}

export default App;
